<template>
	<div class="wrapper">
		<!-- <public-module></public-module> -->
		<!-- 楼层装修组件 -->
		<tpl v-if="isShow" :pageNo="pageNo" :adNo="adNo" ref="tpl" />
		<!-- <f-empty v-else /> -->
	</div>
</template>
<script>
	// #ifdef H5
	window._agl = window._agl || [];
	(function() {
		_agl.push(['production', '_f7L2XwGXjyszb4d1e2oxPybgD']);
		(function() {
			var agl = document.createElement('script');
			agl.type = 'text/javascript';
			agl.async = true;
			agl.src = 'https://fxgate.baidu.com/angelia/fcagl.js?production=_f7L2XwGXjyszb4d1e2oxPybgD';
			var s = document.getElementsByTagName('script')[0];
			s.parentNode.insertBefore(agl, s);
		})();
	})();
	// #endif



	import tpl from "@/views/active-mob/views.vue";
	// import {saveVisitLog} from "@/utils/visitlog.js";

	export default {
		components: {
			tpl,
		},
		data() {
			return {
				rightMenuFlag:false,
				pageNo: undefined,
				adNo: undefined,
				isShow: false,
				background: {
					backgroundColor: "#fff",
				},
				rules: {
					name: {
						required: true,
						message: "请输入姓名",
						// 可以单个或者同时写两个触发验证方式
						trigger: ["blur"],
					},
					// 手机号
					mobile: [{
							required: true,
							message: "请输入手机号",
							trigger: ["change", "blur"],
						},
						{
							// 自定义验证函数，见上说明
							validator: (rule, value, callback) => {
								return this.$u.test.mobile(value);
							},
							message: "手机号码不正确",
							// 触发器可以同时用blur和change
							trigger: ["change", "blur"],
						},
					],
				},
			};
		},

		async mounted() {
            const {pageNo,adNo} = this.$route.query
            console.log("aaa");
			this.pageNo = pageNo
			this.adNo = adNo
			if (this.pageNo != undefined && this.pageNo != null && this.pageNo != "") {
				this.isShow = true;
			}
			// saveVisitLog(adNo);
		},

		onPullDownRefresh() {
			this.$refs.tpl.init();

			uni.stopPullDownRefresh();
		},
		
	};
</script>

<style lang="scss" scoped>
</style>
